import React from 'react';

import UnderLine from "../shared/UnderLine";

import hands from "../../media/icons/hands.png"
import person from "../../media/icons/person.png"
import pile from "../../media/icons/pile.png"
import shield from "../../media/icons/shield.png"
import { useTranslation } from "react-i18next";


const icons = [ hands, person, pile, shield ];

const Advantages = () => {
  const { t } = useTranslation("home", { keyPrefix: 'Advantages' });
  const advantages = t("advantages", { returnObjects: true, defaultValue: [] });
  return (
    <section>
      <h2 className='title text-center text-primary mb-5'>
        {t("title")}
        <UnderLine/>
      </h2>

      <div className="gap-3 flex-center align-items-start flex-wrap mx-auto" style={{maxWidth: 1200}}>
        {advantages.map(({ text, small }, i) =>
          <div key={i} style={{width: 250}} className="flex-center flex-column">
            <img src={icons[i]} alt='' style={{height: '7rem'}}/>
            <div className="text-center py-3">
              <p className="title fw-normal m-0">{text}</p>
              <small>{small}</small>
            </div>
          </div>
        )}
      </div>

      <div className="title mt-5 text-center">
        {t("bottomText")}
      </div>
    </section>
  )
}

export default Advantages;
