import React, { useContext, useEffect, useState } from "react";
import { ModalContext } from "../../wrappers/ModalWrapper";
import { NavLink, useLocation } from "react-router-dom";
import { Row } from 'reactstrap';
import { Trans, useTranslation } from "react-i18next";

const NAME_VAR_STORAGE = "taichi-fighttime-landing-cookies-accept";

const ModalCookies = () => {
  const modal = useContext(ModalContext);
  const { pathname } = useLocation();
  const [accepted, setAccepted] = useState(!!localStorage.getItem(NAME_VAR_STORAGE));
  const [denied, setDenied] = useState(false);

  useEffect(() => {

    const accept = () => {
      localStorage.setItem(NAME_VAR_STORAGE, "true");
      setAccepted(true);
      modal.setContent(null);
    };

    const deny = () => {
      localStorage.removeItem(NAME_VAR_STORAGE);
      setAccepted(false);
      setDenied(true)
      modal.setContent(null);
    };

    if (!accepted && !denied && pathname !== "/terms-and-conditions") {
      modal.setContent(<ModalCookiesContent {...{accept, deny}}/>);
    } else {
      modal.setContent(null)
    }
    // eslint-disable-next-line
  }, [pathname, denied]);
  return null
}

const ModalCookiesContent = ({ accept, deny }) => {  const { t } = useTranslation("shared", { keyPrefix: 'cookiesModal' });

  return (
    <div className="container rounded shadow bg-white text-dark pt-2" style={{width: '98%', maxWidth: 900, marginTop: '3vh'}}>
      <Row className="py-4 px-4 px-md-5" style={{ fontSize: "20px" }}>
        {t("title")}
      </Row>
      <Row className="px-4 px-md-5">
        <Trans
          t={t} i18nKey="text"
          components={{
            p: <p/>,
            Link: <NavLink to="/terms-and-conditions" className="text-primary" style={{ textDecoration: "underline" }}/>
          }}
          values={{ url: window.location.origin }}
        />
      </Row>
      <Row className="justify-content-end pb-4 px-4 px-md-5">
        <button
          onClick={deny}
          style={{ maxWidth: "200px" }}
          className="btn btn-block py-3"
        >{t("deny")}</button>
        <button 
          onClick={accept}
          style={{ maxWidth: "200px" }}
          className="btn text-white btn-block bg-primary rounded py-3"
        >{t("accept")}</button>
      </Row>
    </div>
  )
};

export default ModalCookies;
