import cookies from "./cookies.json";
import legal from "./legal.json";
import privacy from "./privacy.json";
import responsibility from "./responsibility.json";
import termsAndConditions from "./termsAndConditions.json";

const resources = {
  cookiesRows: cookies.tableRows,
  cookies: cookies.text,
  legal: legal.text,
  privacy: privacy.text,
  responsibility: responsibility.text,
  "terms-and-conditions": termsAndConditions.text,
  localizationDisclaimer: "En caso de discrepancia entre la versión en español y cualquier otra versión, prevalecerá la versión en español y se considerará la versión oficial y legal."
};

export default resources;