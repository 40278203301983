import React from 'react';

import { Splide, SplideSlide } from '@splidejs/react-splide';
import { AutoScroll } from '@splidejs/splide-extension-auto-scroll';
import { useTranslation } from "react-i18next";

import img1 from "../../media/trustpilot/1.png"
import img2 from "../../media/trustpilot/2.png"
import img3 from "../../media/trustpilot/3.png"
import img4 from "../../media/trustpilot/4.png"
import img5 from "../../media/trustpilot/5.png"
import img6 from "../../media/trustpilot/6.png"
import img7 from "../../media/trustpilot/7.png"
import img8 from "../../media/trustpilot/8.png"
import img9 from "../../media/trustpilot/9.png"
import img10 from "../../media/trustpilot/10.png"
import img11 from "../../media/trustpilot/11.png"
import logo from "../../media/trustpilot/logo.svg"
import UnderLine from "../shared/UnderLine";

const reviews = [img1, img2, img3, img4, img5, img6, img7, img8, img9, img10, img11]

const Trustpilot = () => {
  const { t } = useTranslation("home", { keyPrefix: 'Trustpilot' });
  return (
    <section className="py-6 text-white" style={{background: 'linear-gradient(0deg, #4864de 040%, #63a4f1 100%)'}}>
      <h2 className='title text-center'>
        {t("title")}
        <UnderLine className="bg-white mb-4"/>
      </h2>
      <div className="flex-center">
        <Splide
          className="bg-white"
          extensions={{AutoScroll}}
          options={{
            autoWidth: true,
            type: "loop",
            drag: "free",
            focus: "center",
            autoScroll: {
              speed: 1
            }
          }}>
          {reviews.map((review, i) => (
            <SplideSlide key={i} className="px-2" style={{width: 350}}>
              <a href="https://es.trustpilot.com/review/investhinks.com">
                <img src={review} className="w-100" alt={"review " + i}/>
              </a>
            </SplideSlide>
          ))}
        </Splide>
      </div>
      <div className="flex-center pt-4">
        <a href="https://es.trustpilot.com/review/investhinks.com">
          <img style={{width: 150}} src={logo} alt="Trustpilot logo"/>
        </a>
      </div>
    </section>
)
}

export default Trustpilot;
