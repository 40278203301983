import React, { useState/*, useEffect, useCallback*/ } from 'react';
import { Form, FormGroup, Label, Input, Button, Alert } from 'reactstrap';
// import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { NavLink/*,, useNavigate*/ } from "react-router-dom";
import UnderLine from "../shared/UnderLine";
import { Trans, useTranslation } from "react-i18next";
// import logo from "../../media/brand/logoSquareWhite.png";


const defaultData = {
  email: '',
  name: '',
  surnames: '',
  phone: '',
  reason: '',
  text: '',
}

const Contact = () => {
  const { t } = useTranslation("home", { keyPrefix: 'Contact' });
  // const navigate = useNavigate();
  // const { executeRecaptcha } = useGoogleReCaptcha();

  const [data, setData] = useState(defaultData)

  const [disabled, setDisabled] = useState(false);
  const [alert, setAlert] = useState(false);


  // const handleReCaptchaVerify = useCallback(async () => {
  //   if (!executeRecaptcha) return;
  //   const token = await executeRecaptcha('yourAction');
  //   setDisabled(!Boolean(token))
  //   // eslint-disable-next-line
  // }, []);

  // useEffect(() => {
  //   handleReCaptchaVerify();
  // }, [handleReCaptchaVerify]);


  const handleInputChange = (event) => {
    setAlert(false);
    setData({
      ...data,
      [event.target.name]: event.target.value
    })
  }

  const handleSubmit = (event) => {
    // handleReCaptchaVerify();
    event.preventDefault();
    const {name, surnames, phone, email, reason, text} = data

    fetch('https://api.web3forms.com/submit', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      body: JSON.stringify({
        'Nombre': name + " " + surnames,
        'Teléfono': phone,
        'Correo': email,
        'Asunto': reason,
        'Mensaje': text,

        from_name: 'Landing Investhinks - Formulario de contacto',
        subject: reason,
        replyto: email,
        access_key: process.env.REACT_APP_W3FORMS_KEY
      })
    })
      .then(() => {
        setDisabled(true);
        // navigate('/form-sent')
        setAlert(true);
        setData(defaultData)
      })
  }

  
  return (
    <section
      style={{ background: 'linear-gradient(0deg, #3539d1 040%, #4864de 100%)', zIndex: 0 }}
      className="py-5 overflow-hidden"
    >

      <h2 className="text-white text-center px-0 mb-4 text-uppercase" style={{ fontWeight: 700 }}>
        {t("title")}
        <UnderLine className="bg-white" width={100}/>
      </h2>
      {/*<Row tag="p" className="mx-auto text-center bigText mb-4" style={{fontWeight: 400, maxWidth: 600}}>*/}
      {/*  Pre-lanzamiento en exclusiva, rellena el formulario para solicitar más información.*/}
      {/*</Row>*/}
      <Form
        onSubmit={handleSubmit}
        className="mx-auto w-100 bg-light p-4 rounded mr-lg-7"
        style={{ maxWidth: 500, borderWidth: '20px !important;' }}
      >

        <FormGroup>
          <Label for="email">{t("form.email")}</Label>
          <Input required type="email" name="email" value={data.email} onChange={handleInputChange}/>
        </FormGroup>
        <FormGroup>
          <Label for="name">{t("form.name")}</Label>
          <Input required type="text" name="name" value={data.name} onChange={handleInputChange}/>
        </FormGroup>
        <FormGroup>
          <Label for="surnames">{t("form.surnames")}</Label>
          <Input required type="text" name="surnames" value={data.surnames} onChange={handleInputChange}/>
        </FormGroup>
        <FormGroup>
          <Label for="phone">{t("form.phone")}</Label>
          <Input required type="tel" name="phone" value={data.phone} onChange={handleInputChange}/>
        </FormGroup>
        <FormGroup>
          <Label for="clubName">{t("form.reason")}</Label>
          <Input required type="text" name="reason" value={data.reason} onChange={handleInputChange}/>
        </FormGroup>
        <FormGroup>
          <Label for="clubName">{t("form.text")}</Label>
          <Input required type="textarea" name="text" rows={4} value={data.text} onChange={handleInputChange}/>
        </FormGroup>

        <FormGroup check>
          <Input required type="checkbox"/>
          <Label>
            <Trans t={t} i18nKey="form.privacy" components={[<NavLink to="/privacy" className="text-primary"/>]}/>
          </Label>
        </FormGroup>

        <div className="d-flex justify-content-center mt-2">
          <Button
            disabled={disabled}
            type="submit"
            color="primary"
            className="rounded w-50"
          >{t("form.submit")}</Button>
        </div>


        <Alert color="success" isOpen={alert} className="mt-3">
          {t("form.successAlert")}
        </Alert>

      </Form>
    </section>
  )
}

export default Contact;