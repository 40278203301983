import React from 'react';

import xm from "../../media/partners/xm.png"
import equiti from "../../media/partners/equiti.png"
import swissquote from "../../media/partners/swissquote.svg"
import revolut from "../../media/partners/squared.png"
import squared from "../../media/partners/revolut.svg"
import metatrader from "../../media/partners/metatrader.png"
import { useTranslation } from "react-i18next";

const OurPartners = () => {
  const { t } = useTranslation("home", { keyPrefix: 'OurPartners' });
  return (
    <section className="my-5 mt-md-0">
      <h2 className="title text-center text-primary mb-5">
        {t("title")}
      </h2>
      <div className="flex-center gap-5 flex-wrap">
        {[xm, equiti, swissquote, squared, revolut, metatrader].map(img =>
          <img src={img} alt="" className="mt-4"
               style={{maxHeight: 90, minHeight: 60, width: 400, maxWidth: "100%", objectFit: "contain"}}/>
        )}
      </div>
    </section>
  )
}

export default OurPartners;
