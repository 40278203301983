import React, { useRef } from "react";
import { Container, Button } from "reactstrap"
import { Element } from 'react-scroll'
import { NavLink } from "react-router-dom";
import { useInView, motion, AnimatePresence } from "framer-motion";

import scrollTo from "../components/home/utils/scrollTo";

import Navbar from "../components/shared/Navbar"
import MainSection, { Promo } from "../components/home/MainSection"
import Advantages from "../components/home/Advantages"
import Easy from "../components/home/Easy";
// import Plans from "../components/home/Plans";
// import Price from "../components/home/Price";
import OurPartners from "../components/home/OurPartners";
import Chart from "../components/home/Chart";
// import Onboarding from "../components/home/Onboarding";
// import FAQs from "../components/home/FAQs";
import Slogan from "../components/home/Slogan";
import Contact from "../components/home/Contact";
import Footer from "../components/shared/Footer"
import { Trans, useTranslation } from "react-i18next";
// import img from "../media/slogan.png";
import Trustpilot from "../components/home/Trustpilot";


const Home = ({referralCode}) => {
  const {t} = useTranslation("home");

  const ref = useRef(null)
  const isInView = useInView(ref);

  const navLinks = [
    {text: t("navLinks.home"), handleClick: () => window.location.href = '/'},
    // { text: 'Niveles', handleClick: () => scrollTo('risks') },
    {text: t("navLinks.contact"), handleClick: () => scrollTo('contact')},
    {
      text: <Trans t={t} i18nKey="navLinks.logIn"
                   components={[<Button color="primary" className="mt-n3 mt-xl-0 ms-xl-4"/>]}/>,
      handleClick: () => window.location.href = 'https://dashboard.investhinks.com',
    },
    // { text:
    //     <Button color="primary" className="mt-n3 mt-xl-0 ms-xl-n3">
    //       Regístrate
    //     </Button>,
    //   handleClick: () => window.location.href = `https://dashboard.investhinks.com/auth/signup?referralCode=${referralCode}`
    // },
  ]

  return (
    <Container fluid className="metropolis bg-white p-0 position-relative overflow-hidden">

      <div className="px-5 py-2 text-center bg-warning text-white border-bottom border-3 user-select-none" style={{fontSize: ".7rem"}}>
        <Trans t={t} i18nKey="warning"
               components={[<NavLink to="/signUp-disclaimer" className="text-white fw-bold text-decoration-none"/>]}/>
      </div>

      <Navbar links={navLinks}/>


      <MainSection style={{minHeight: 'calc(100vh - 60px - 59px)'}} referralCode={referralCode}/>

      <div ref={ref} style={{transform: "translateY(-50vh)"}}/>
      {/*<Element name="onboarding" style={{transform: 'translateY(-10vh)'}}/>*/}
      {/*<Onboarding referralCode={referralCode}/>*/}

      <Advantages/>

      <Easy/>

      <Chart/>

      {/*<Element name="risks" style={{transform: 'translateY(-10vh)'}}/>*/}
      {/*<Plans referralCode={referralCode}/>*/}

      {/*<Price />*/}


      <Element name="partners" style={{transform: 'translateY(-10vh)'}}/>
      <OurPartners/>

      {/*<FAQs />*/}

      <Slogan/>

      <Trustpilot/>

      <Element name="contact" style={{transform: 'translateY(-10vh)'}}/>
      <Contact/>

      <Footer/>


      <AnimatePresence>
        {!isInView && <FloatingCTA referralCode={referralCode}/>}
      </AnimatePresence>
    </Container>
  );
}

const FloatingCTA = ({referralCode}) => {
  const {t} = useTranslation("home", {keyPrefix: 'MainSection'});
  return (
    <motion.div
      style={{ position: "fixed", bottom: "3rem", right: "1.5rem", maxWidth: "calc(100vw - 3rem)" }}
      initial={{y: "300%"}} animate={{y: 0}} exit={{y: "300%"}} transition={{type: "linear"}}
    >
      <div className="position-relative d-flex justify-content-end">
        <div className="w-100 text-end">
          <a href={`https://dashboard.investhinks.com/auth/signup?${referralCode ? `refCode=${referralCode}` : ""}`}
             rel="noreferrer">
            <Button color="light" className="text-primary rounded px-4 py-3 mx-auto shadow" size="lg" style={{ maxWidth: "100%", width: 250 }}>
              {t("cta")}
            </Button>
            <div
              style={{
                position: "absolute",
                top: 0,
                right: 0,
                transform: "translate(25%, -75%) scale(.7)",
              }}
            ><Promo/></div>
          </a>
        </div>
      </div>
    </motion.div>
  )
}


export default Home;