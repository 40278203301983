import React, { useEffect, useState } from "react";
import { Navigate, Routes, Route, useLocation, useSearchParams, NavLink } from "react-router-dom";

import ModalWrapper from "./wrappers/ModalWrapper";
import ModalCookies from "./components/shared/ModalCookies";
import Home from "./pages/Home"

import LegalContainer from "./pages/LegalContainer";
import useCookie from "./hooks/useCookie";
import Loading from "./pages/Loading";
import { Trans, useTranslation } from "react-i18next";
import config from "./components/legalTexts/config.json";

const legalRoutes = [
  "legal",
  "privacy",
  "cookies",
  "terms-and-conditions",
  "responsibility"
]

const cookiesLinks = {
  FirefoxCookiesLink: <a href="http://support.mozilla.org/es/products/firefox/privacy-and-security"
                               rel="noreferrer"
                               target="_blank">http://support.mozilla.org/es/products/firefox/privacy-and-security</a>,
  ChromeCookiesLink: <a href="http://support.google.com/chrome/bin/answer.py?hl=es&answer=95647"
                              rel="noreferrer"
                              target="_blank">http://support.google.com/chrome/bin/answer.py?hl=es&answer=95647</a>,
  OperaCookiesLink: <a href="http://www.opera.com/help/tutorials/security/cookies/" rel="noreferrer"
                             target="_blank">http://www.opera.com/help/tutorials/security/cookies/</a>
}


const App = () => {
  const { t } = useTranslation("legalTexts");
  const [cookie, updateCookie] = useCookie("investhinks_referralCode", null)

  let [searchParams] = useSearchParams();
  const referralCode = searchParams.get('code')

  useEffect(() => {
    if (referralCode) updateCookie(referralCode, 30)
    // eslint-disable-next-line
  }, [referralCode])


  const [validReferral, setValidReferral] = useState()
  const code = referralCode || cookie
  useEffect(() => {
    if (code) {
      fetch(process.env.REACT_APP_API_URL + '/referral-codes/valid/' + code)
        .then(r => r.json())
        .then(r => setValidReferral(r))
    } else setValidReferral(false)
    // eslint-disable-next-line
  }, [cookie])

  if (validReferral === undefined) return <Loading/>
  return (
    <ModalWrapper>
      <ScrollToTop/>
      <ModalCookies/>
      <Routes>
        {/*{!validReferral ?*/}
        {/*  <Route path="/" element={<InvalidReferral/>}/>*/}
        {/*: <>*/}
        <Route path="/" element={<Home referralCode={validReferral && code}/>}/>
        {/*</>*/}
        {/*}*/}
        {legalRoutes.map((route, i) =>
          <Route
            key={i}
            path={"/" + route}
            element={
              <LegalContainer referralCode={validReferral && code}>
                <Trans
                  t={t} i18nKey={route}
                  components={{
                    br: <br/>, h1: <h1/>, h2: <h2/>, u: <u/>, ul: <ul/>, li: <li/>,
                    ol_type_a: <ol type="a"/>, ol_type_i: <ol type="i"/>,
                    table: <table/>, thead: <thead/>, tbody: <tbody/>, tr: <tr/>, th: <th/>,
                    LegalLink: <NavLink to="/legal"/>,
                    contact: <a href={`mailto:${config.email}`}/>,
                    hostLink: <a href={`https://${window.location.host}/`}/>,
                    TableRows: <CookiesRows />,
                    ...cookiesLinks
                  }}
                  values={{...config, host: window.location.host}}
                />
              </LegalContainer>
            }/>
        )}
        <Route path="*" element={<Navigate to="/" replace/>}/>
      </Routes>
    </ModalWrapper>
  );
}


const CookiesRows = () => {
  const { t } = useTranslation("legalTexts");
  return (
    t("cookiesRows", { returnObjects: true, defaultValue: [] }).map(({separator, cookie, type, duration, description}, i) =>
      <tr key={i}>
        {separator ?
          <th colSpan="100%">{separator}</th>
          :
          <>
            <td>{cookie}</td>
            <td>{type}</td>
            <td>{duration}</td>
            <td>{description}</td>
          </>
        }
      </tr>
    )
  )
}


const ScrollToTop = () => {
  const {pathname} = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return null;
};

export default App;
