import { scroller } from "react-scroll";

const scrollTo = (element) => {
  scroller.scrollTo(element, {
    duration: 100,
    smooth: true,
    dynamic: true
  })
}

export default scrollTo