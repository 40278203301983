import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import detector from "i18next-browser-languagedetector";
import resources from "./locales";

i18n
  .use(detector)
  .use(initReactI18next)
  .init({
    resources,
    load: 'languageOnly', // Ignore country
    fallbackLng: ["en", "es"],
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;