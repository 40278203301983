import cookies from "./cookies.json";
import legal from "./legal.json";
import privacy from "./privacy.json";
import responsibility from "./responsibility.json";
import termsAndConditions from "./termsAndConditions.json";

const resources = {
  cookiesRows: cookies.tableRows,
  cookies: cookies.text,
  legal: legal.text,
  privacy: privacy.text,
  responsibility: responsibility.text,
  "terms-and-conditions": termsAndConditions.text,
  localizationDisclaimer: "In case of discrepancy between the Spanish version and any other version, the Spanish version will prevail and will be considered the official and legal version."
};

export default resources;