import React from "react";
import { Button } from "reactstrap";
import { Trans, useTranslation } from "react-i18next";

import image from "../../media/mainImg.png"

const MainSection = ({style, referralCode}) => {
  const { t } = useTranslation("home", { keyPrefix: 'MainSection' });
  return (
    <section className="flex-center" style={{...styles.bg, ...style}}>
      <div className="text-white d-md-flex py-2 mt-md-5" style={{maxWidth: 1300,  transform: 'translateY(-5vh)'}}>
        <div className="text-center me-md-3 h-100">
          <img src={image} alt="" className="w-auto" style={{height: 600, maxHeight: '60vh'}}/>
        </div>

        <div className="flex-center flex-column mx-auto py-md-5">
          <h1 className="mainTitle">
            {t("title")}
          </h1>
          <div className="mt-4">
            <Trans t={t} i18nKey="text" components={{ p: <p/> }}/>
          </div>
          <div className="position-relative">
            <a href={`https://dashboard.investhinks.com/auth/signup?${referralCode ? `refCode=${referralCode}` : ""}`}
               rel="noreferrer">
              <Button color="light" className="text-primary rounded mt-4 px-4 py-3 mx-auto">
                {t("cta")}
              </Button>
            </a>

            <div className="position-absolute" style={{bottom: 0, right: 0, transform: "translate(70%, 80%)" }}>
              <Promo />
            </div>
          </div>

        </div>
      </div>
    </section>
  )
}


export const Promo = () => {
  const {t} = useTranslation("home", {keyPrefix: 'MainSection'});
  return (
    <div
      className="rounded-circle bg-danger text-white p-3 flex-center flex-column text-center"
      style={{
        height: "10rem",
        width: "10rem"
      }}
    >
      <div className="h4 fw-bolder mb-1">
        <Trans t={t} i18nKey="promo.title" components={{br: <br/>}}/>
      </div>
      <div className="small mb-n1">
        <Trans t={t} i18nKey="promo.subtitle" components={{br: <br/>, b: <b/>}}/>
      </div>
    </div>
  )
}

const styles = {
  bg: {
    background: 'linear-gradient(0deg, #71c4fb 21%, #3539d1 100%)'
  }
}


export default MainSection;
