import React from 'react';
import img from "../../media/slogan.png"
import { Button } from "reactstrap";
import { useTranslation } from "react-i18next";

const Slogan = () => {
  const { t } = useTranslation("home", { keyPrefix: 'MainSection' });
  return (
    <section className="px-0 text-center bg-primary" style={{ background: "#4864de" }}>
      <div>
        <img src={img} className="w-100" alt="" style={{maxWidth: 1000}}/>
      </div>
      <Button color="light" size="lg" className="text-primary px-5 py-3 fs-2 my-5 w-50 text-nowrap">{t("cta")}</Button>
    </section>
  )
}

export default Slogan;
