import React from "react";
import { Container } from "reactstrap"
import { MoonLoader } from "react-spinners";

import Navbar from "../components/shared/Navbar"
import Footer from "../components/shared/Footer"

const InvalidReferral = () => {
  return (
    <Container fluid className="metropolis bg-white text-dark p-0">
      <Navbar/>
      <Container
        fluid
        style={{
          minHeight: 'calc(100vh - 60px - 54px)',
          marginTop: 60,
          background: 'linear-gradient(0deg, #3539d1 040%, #63a4f1 100%)'
        }}
        className="py-5 px-4 text-white h-100 flex-center flex-column"
      >
        <MoonLoader color="white" />
      </Container>
      <Footer />
    </Container>
  );
}

export default InvalidReferral;