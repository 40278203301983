import React, { useMemo, useState } from "react";
import { Row, Col, Collapse, DropdownMenu, Dropdown, DropdownToggle, DropdownItem } from "reactstrap";
import Hamburger from 'hamburger-react'
import { motion } from "framer-motion";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

import logo from "../../media/brand/logoDark.png"

import spain from "../../media/flags/spain.png"
// import germany from "../../media/flags/germany.png"
// import france from "../../media/flags/france.png"
// import italy from "../../media/flags/italy.png"
import uk from "../../media/flags/united-kingdom.png"


const Navbar = ({ links = [] }) => {
  const [isOpen, setOpen] = useState(false)


  const onClick = handleClick => () => {
    setOpen(false)
    handleClick()
  }

  return (
    <Row className="w-100 px-3 px-md-3 position-sticky text-dark bg-white m-0 shadow-sm" style={{ top: 0, left: 0, zIndex: 100, minHeight: 60 }}>
      
      <Col xs="12" xl="auto" className="d-flex ps-md-3 align-items-center pe-2">
        <NavLink to="/" className="flex-center" style={{height: "60px"}}>
          <img src={logo} style={{ height: "25px" }} alt=""/>
        </NavLink>
        {Boolean(links.length) &&
          <span className="ms-auto d-xl-none flex-center">
            <LanguageSelector />
            <Hamburger toggled={isOpen} toggle={setOpen} size={20} aria-label="menu"/>
          </span>
        }
      </Col>

      <Col className="align-items-center justify-content-end d-none d-xl-flex" style={styles.linkBox}>
        {links.map(({text, handleClick}, i) =>
          <span key={i} onClick={onClick(handleClick)} className="link" style={styles.navLink}>
            {text}
          </span>
        )}
        <LanguageSelector />
      </Col>
      
      <Collapse isOpen={isOpen} className="mx-auto d-xl-none">
        <div className="d-flex flex-column py-4 px-2 text-center" style={styles.linkBox}>
          {links.map(({text, handleClick}, i) =>
            <span key={i} onClick={onClick(handleClick)} className="link" style={styles.navLink}>
              {text}
            </span>
          )}
        </div>
      </Collapse>

    </Row>
  )
}


const languages = [
  {
    code: 'es',
    flag: spain,
    name: "Español"
  },
  // {
  //   code: 'fr',
  //   flag: france,
  //   name: "François"
  // },
  // {
  //   code: 'de',
  //   flag: germany,
  //   name: "Deutsch"
  // },
  // {
  //   code: 'it',
  //   flag: italy,
  //   name: "Italiano"
  // },
  {
    code: 'en',
    flag: uk,
    name: "English"
  }
]

const LanguageSelector = () => {
  const { i18n } = useTranslation();
  const [isOpen, setOpen] = useState(false);
  const toggle = () => setOpen((prevState) => !prevState);

  const selectedLang = useMemo(() => languages.find(l => l.code === i18n.language) || languages[0], [i18n.language])

  return (
    <Dropdown isOpen={isOpen} toggle={toggle}>
      <DropdownToggle className="bg-transparent border-0 ps-0">
        <motion.img
          whileHover={{scale: 1.1}}
          whileTap={{scale: 1}}
          src={selectedLang.flag}
          style={{height: "35px", cursor: "pointer"}}
          alt={selectedLang.name}
        />
      </DropdownToggle>
      <DropdownMenu end>
        {languages.map(({ code, flag, name }, i) =>
          <DropdownItem key={i} active={i18n.language === code} onClick={() => i18n.changeLanguage(code)}>
            <img src={flag} style={{height: "35px"}} alt={name}/> {name}
          </DropdownItem>
        )}
      </DropdownMenu>
    </Dropdown>
  )
}



const styles = {
  linkBox: {
    fontSize: "16px",
    whiteSpace: "nowrap",
    gap: '1.5rem'
  },
  navLink: {
    cursor: "pointer",
    fontWeight: "bold"
  }
}

export default Navbar;
