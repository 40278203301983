import React from 'react';

import click from "../../media/icons/click.png"
import lock from "../../media/icons/lock.png"
import gear from "../../media/icons/gear.png"
import { useTranslation } from "react-i18next";


const icons = [click, lock, gear]

const Easy = () => {
  const { t } = useTranslation("home", { keyPrefix: 'Easy' });
  const cards = t("cards", { returnObjects: true, defaultValue: [] });
  return (
    <section className="bg-primary py-7">
      <div
        className="gap-5 d-flex justify-content-center align-items-stretch flex-wrap mx-auto bg-white rounded py-5 py-md-2 px-4"
        style={{width: 'fit-content'}}>
        {cards.map(({title, text}, i) =>
          <div key={i} style={{width: 230}} className="text-center py-4 d-flex flex-column align-items-center">
            <div className="title text-primary">{title}</div>
            <div className="my-3">
              <img src={icons[i]} alt='' style={{height: 50}}/>
            </div>
            <p className="flex-center flex-grow-1 m-0">{text}</p>
          </div>
        )}
      </div>
    </section>
  )
}
export default Easy;
