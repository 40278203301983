import React from "react";
import { Row, Col } from "reactstrap";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation("footer");
  return (
    <Row className="bg-dark px-3 m-0">
      <Col className="d-flex py-3 pl-2 align-items-center flex-wrap" style={styles.links}>
        <NavLink to="/legal" className="text-white">{t("legal")}</NavLink>
        <span className="text-white">|</span>
        <NavLink to="/cookies" className="text-white">{t("cookies")}</NavLink>
        <span className="text-white">|</span>
        <NavLink to="/privacy" className="text-white">{t("privacy")}</NavLink>
        <span className="text-white">|</span>
        <NavLink to="/terms-and-conditions" className="text-white">{t("terms-and-conditions")}</NavLink>
        <span className="text-white">|</span>
        <NavLink to="/responsibility" className="text-white">{t("responsibility")}</NavLink>
      </Col>
    </Row>
  )
}

const styles = {
  links: {
    gap: 10,
    fontSize: "12px",
    lineHeight: "18px",
  }
}

export default Footer;
