import React from "react";
import { Button, Container } from "reactstrap"

import Navbar from "../components/shared/Navbar"
import Footer from "../components/shared/Footer"
import { Trans, useTranslation } from "react-i18next";


const LegalContainer = ({children, referralCode}) => {
  const { t } = useTranslation("legalTexts")
  const navLinks = referralCode ? [
    { text: <Trans ns="home" i18nKey="navLinks.logIn" components={[<Button color="primary" className="mt-n3 mt-xl-0 ms-xl-4"/>]} />,
      handleClick: () => window.location.href = 'https://dashboard.investhinks.com',
    },
    // { text:
    //     <Button color="primary" className="mt-n3 mt-xl-0 ms-xl-n3">
    //       Regístrate
    //     </Button>,
    //   handleClick: () => window.location.href = `https://dashboard.investhinks.com/auth/signup?referralCode=${referralCode}`
    // },
  ] : []

  return (
    <Container fluid className="metropolis bg-white text-dark p-0">
      <Navbar links={navLinks}/>
      <Container style={{maxWidth: 1300, minHeight: 'calc(100vh - 60px - 54px)', marginTop: 60}} className="py-5 px-4 legalText">
        {children}
        <div className="h5 text-warning mt-5 text-center">{t("localizationDisclaimer")}</div>
      </Container>
      <Footer />
    </Container>
  );
}

export default LegalContainer;